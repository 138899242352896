import React, { useEffect } from "react";

// comps
import { Section, SectionContent, Seo } from "../components";
import Layout from "../components/Layout";

const Imprint = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Seo title="Imprint" />
      <Section title="Imprint">
        <SectionContent customClass="imprint">
          <h4>Statement in compliance with § 5 TMG</h4>
          <p>
            The Velvet Cell, Éanna de Fréine, Luxemburgerstr 30, 13353, Berlin
            Germany
          </p>
          <h4>Contact</h4>
          <p>Phone: 0049.764.201 4387, eanna@thevelvetcell.com</p>
          <h4>VAT registration number</h4>
          <p>
            VAT registration number in compliance with § 27 UStG (German
            Value-Added Tax Act): DE 314566027
          </p>
          <h4>
            Responsible for the content according to § 55 Abs. 2 RStV
            (Interstate Broadcast Agreement)
          </h4>
          <p>Éanna de Fréine, Luxemburgerstr 30, 13353, Berlin, Germany</p>
          <h4>Copyright of hosted images and graphics</h4>
          <p>
            All copyrights remain with the respective artists presented on this
            website.
          </p>
          <h4>
            Alternative Dispute Resolution in accordance with Art. 14 (1) ODR-VO
            and § 36 VSBG:
          </h4>
          <p>
            The European Commission provides a platform for Online Dispute
            Resolution (ODR), which can be found at
            https://ec.europa.eu/consumers/odr. We are not obliged or willing to
            participate in a dispute resolution procedure before a consumer
            arbitration board.
          </p>
        </SectionContent>
      </Section>
    </Layout>
  );
};

export default Imprint;
